import axios, {
    AxiosError,
    AxiosInstance,
    AxiosPromise,
    AxiosRequestConfig,
    AxiosResponse,
    CancelToken,
} from 'axios';
import { ErrorCodes } from './Constants/ApiConstants';
import { Features } from '../../../src/models/enums/general-enums';
import { IApi, SDKType, ApiResponse } from './Interfaces/IApi';
import { ILogger } from '@sparkware/uc-sdk-core';
import Container from 'typedi';
import { LoggerProvider } from '../../../src/modules/logger';

const NativeSDKTypes = [SDKType.NativeSDK, SDKType.AppWrapper];

export class ApiWrapper implements IApi {
    private readonly _axiosInstance: AxiosInstance;
    private readonly _window: Window;
    private readonly _logger: ILogger;

    public get AxiosInstance() {
        return this._axiosInstance;
    }

    constructor(windowToken: Window, config: AxiosRequestConfig) {
        this._window = windowToken;
        this._axiosInstance = axios.create(config);
        this._logger = Container.get(LoggerProvider).getLogger('ApiWrapper');
    }

    public createCancelToken = (executor): CancelToken => {
        return new axios.CancelToken(executor);
    };

    public request = async (options: AxiosRequestConfig): Promise<any> => {
        const requestFn = this._axiosInstance;
        const processedResponse = await requestFn(options)
            .then(this._onSuccess)
            .catch(this._onError);

        this._logResponse(processedResponse);

        return processedResponse;
    };

    public retriedRequest = async (
        options: AxiosRequestConfig,
        retryCount: number,
        delayMsBetweenRetries: number = 0,
    ): Promise<any> => {
        let promise: AxiosPromise<any> = Promise.reject();
        const requestFn = this._axiosInstance;

        for (let i = 0; i < retryCount; i++) {
            promise = promise
                .catch(() => requestFn(options))
                .catch((reason) => this._delayedReject(reason, delayMsBetweenRetries));
        }

        const processedResponse = await promise.then(this._onSuccess).catch(this._onError);

        this._logResponse(processedResponse, retryCount);
        return processedResponse;
    };

    public authenticatedRequest = async (options: AxiosRequestConfig): Promise<any> => {
        var requestFn = this._axiosInstance;

        let requestOptions: AxiosRequestConfig;
        const isNativeSdk = this._isNativeSdk();
        if (!isNativeSdk) {
            requestOptions = this._setAuthenticationHeaders(options);

            if (!requestOptions.headers.authorization) {
                const errorMessage = `API: authenticatedRequest: ${options.url}: Missing authorization token...`;
                this._logger.warn(errorMessage);
                this._window.PF?.Web?.ClientsFramework?.Logout?.doLogoutRefreshState({
                    isDialogShown: false,
                });
                const response: Partial<ApiResponse> = {
                    errorResponse: errorMessage,
                };

                return response;
            }
        } else {
            requestOptions = { ...(options || {}) };
            if (!requestOptions.headers) requestOptions.headers = {};
            requestOptions.headers.nativeSdkAuthenticated = 'true';
        }

        const processedResponse = await requestFn(requestOptions)
            .then(this._onSuccess)
            .catch(!isNativeSdk ? this._onAuthenticatedRequestError : this._onError);

        this._logResponse(processedResponse);

        return processedResponse;
    };

    /* #region Privates */

    private _onSuccess = (response: AxiosResponse): any => {
        this._updateExpirationToken(response);

        const successResponse: Partial<ApiResponse> = {
            response: response.data,
            duration: response['duration'],
            config: response.config,
            statusCode: response.status,
            statusText: response.statusText,
            errorCode: response?.data?.ErrorCode > 100 ? response?.data?.ErrorCode : undefined,
            errorDescription:
                response?.data?.ErrorCode > 100 ? response?.data?.ErrorDescription : undefined,
        };

        return successResponse;
    };

    private _onAuthenticatedRequestError = (error: AxiosError) => {
        const FORBIDDEN_STATUS_CODE = 401;
        if (error && error.response && error.response.status === FORBIDDEN_STATUS_CODE)
            // TODO - maybe we should wait for this?
            this._window.PF?.Web?.ClientsFramework?.Logout?.doLogoutAndShowTimeoutDialog();

        return this._onError(error);
    };

    private _onError = (error: AxiosError | any) => {
        const errorConfig = this.sanitizeErrorConfig(error.config);

        const errorResponse: Partial<ApiResponse> = {
            duration: error['duration'],
            config: errorConfig,
            statusCode: error.status,
            statusText: error.statusText,
            errorCode: error.code,
            errorDescription: error.message,
        };

        if (axios.isCancel(error)) {
            errorResponse.cancelled = true;
            return { errorResponse };
        }

        const errorMessage = `API: _onError: Request Failed: ${JSON.stringify(errorConfig)}
      ${
          error.response
              ? `
          Status: ${JSON.stringify(error.response.status)}
          Data: ${JSON.stringify(error.response.data)}
          Headers: ${JSON.stringify(error.response.headers)}
            `
              : `Error Message: ${JSON.stringify(error.message)}`
      }`;

        if (this._isSessionDataMissing(error.response?.data)) {
            this._logger.info(errorMessage);
        } else {
            this._logger.warn(errorMessage);
        }

        errorResponse.errorResponse = error.response || error.message;

        this._handleExceptions(error);

        return errorResponse;
    };

    private _isLogoutRequired = (data) =>
        [ErrorCodes.MissingUserData, ErrorCodes.InternalServerError].some(
            (errorCode) => errorCode === data?.error?.code,
        );
    private _isSessionDataMissing = (data) => ErrorCodes.MissingUserData === data?.error?.code;

    private _handleExceptions = (error: AxiosError) => {
        const data: any = error?.response?.data;

        if (!data) return;

        if (this._isLogoutRequired(data))
            // TODO - maybe we should wait for this?
            this._window.PF?.Web?.ClientsFramework?.Logout?.doLogoutRefreshState({
                isDialogShown: true,
            });
    };

    private _updateExpirationToken = (response): void => {
        let responseDate: number = null;
        let responseDateString: string = response.headers['date'];

        if (responseDateString !== null) responseDate = Date.parse(responseDateString);
        else responseDateString = 'No Date';

        var expirationToken = response.headers.expirationtoken;
        if (expirationToken) {
            const isSingleTabLoggedInEnabled = this._isFeatureEnabled(
                Features.SINGLE_TAB_LOGGED_IN,
            );

            const currentExpirationData = isSingleTabLoggedInEnabled
                ? (sessionStorage.expirationData && JSON.parse(sessionStorage.expirationData)) ||
                  null
                : (localStorage.expirationData && JSON.parse(localStorage.expirationData)) || null;

            if (
                !currentExpirationData ||
                !currentExpirationData.date ||
                responseDate >= currentExpirationData.date
            ) {
                const expirationData = {
                    token: expirationToken,
                    date: `${responseDate}`,
                };

                if (isSingleTabLoggedInEnabled)
                    sessionStorage.setItem('expirationData', JSON.stringify(expirationData));
                else localStorage.setItem('expirationData', JSON.stringify(expirationData));
            }
        }
    };

    private _setAuthenticationHeaders = (options: AxiosRequestConfig): AxiosRequestConfig => {
        let returnedOptions: AxiosRequestConfig = { ...(options || {}) };
        const isSingleTabLoggedInEnabled = this._isFeatureEnabled(Features.SINGLE_TAB_LOGGED_IN);

        const authorizationDataToken = JSON.parse(
            (isSingleTabLoggedInEnabled
                ? sessionStorage.authorizationData
                : localStorage.authorizationData) || '{}',
        );

        if (!returnedOptions.headers) returnedOptions.headers = {};

        const token = authorizationDataToken?.token;
        const expirationToken = JSON.parse(
            (isSingleTabLoggedInEnabled
                ? sessionStorage.expirationData
                : localStorage.expirationData) || '{}',
        )?.token;

        if (token) returnedOptions.headers.authorization = `Bearer ${token}`;
        if (expirationToken) returnedOptions.headers.expirationToken = expirationToken;

        return returnedOptions;
    };

    private _logResponse = (processedResponse: ApiResponse, retryCount: number = undefined) => {
        const currentUrl = processedResponse?.config?.url;
        if (!currentUrl) {
            const errorMessage = `API: _logResponse: Missing url inside ApiResponse`;
            this._logger.warn(errorMessage);
        }
        const clickStreamUrl = this._window.pageContextManager.getUrlResourceData()?.clickStreamUrl;
        if (currentUrl === clickStreamUrl) {
            return;
        }
        const parsedUrl = new URL(currentUrl, this._window.location.origin);

        const eventData = {
            baseURL: processedResponse?.config?.baseURL,
            cancelled: processedResponse?.cancelled,
            durationInMS: processedResponse?.duration,
            errorCode: processedResponse?.errorCode,
            errorDescription: processedResponse?.errorDescription,
            event: 'uc-api-response',
            path: currentUrl,
            apiPath: parsedUrl?.pathname,
            retryCount,
            statusCode: processedResponse.statusCode,
            statusText: processedResponse.statusText,
        };

        this._window.libraryManager.UCSDKLibrary.ready.then((uc: any) => {
            uc.channels.tracking.topics.sendEvent.publish(
                {
                    publisher: 'api',
                    correlationID: processedResponse?.config?.headers?.['X-Correlation-ID'],
                },
                eventData,
            );
        });
    };

    private sanitizeErrorConfig = (errorConfig) => {
        const copyErrorConfig: any = { ...errorConfig };

        let parsedData: any = {};
        try {
            parsedData = JSON.parse(copyErrorConfig?.data);
        } catch (err) {
            parsedData = null;
        }

        if (parsedData?.AuthenticationInfo) {
            parsedData.AuthenticationInfo = '#SECRET#';
        }

        if (parsedData?.UserInfo) {
            parsedData.UserInfo = '#SECRET#';
        }

        copyErrorConfig.data = parsedData ? JSON.stringify(parsedData) : copyErrorConfig.data;

        return copyErrorConfig;
    };

    private _isNativeSdk = (): boolean => {
        const sdkType = this._window.pageContextManager.getSiteData().sdkType;

        return NativeSDKTypes.some((type) => type === sdkType);
    };

    private _delayedReject = <T>(reason: T, timeoutMs: number): Promise<T> => {
        return new Promise((resolve, reject) => {
            setTimeout(reject.bind(null, reason), timeoutMs);
        });
    };

    private _isFeatureEnabled = (featureName: string, genericFeatureName?: string): boolean => {
        if (!featureName) return false;

        const activeFeatures = this._window.pageContextManager
            .getFeaturesData()
            .features.filter((feature) => feature.value.toLowerCase() === 'on');

        if (!activeFeatures || !activeFeatures.length) return false;

        return activeFeatures?.some(
            (activeFeature) =>
                activeFeature?.id.toString().toLowerCase() === featureName?.toLowerCase() ||
                activeFeature?.id.toString().toLowerCase() === genericFeatureName?.toLowerCase(),
        );
    };
}
