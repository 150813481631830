import Container from 'typedi';
import UserApi from '../../../../APIs/UserApi';
import DeferredObject from '../../../../Modules/Utils/DeferredObject';
import { ApiErrorCodes, StatusCodes } from '../../../models/enums/general-enums';
import { MessageBroker, IdentityChannel } from '@sparkware/uc-sdk-core';
import { FeatureAvailabilityBase } from './feature-availability-base';
import { IUserContext } from '../../user-context/user-context-interface';
import { UserContextToken, UserDataStoreDeferredObjectToken } from '../../../injection-tokens';
import { ISessionUserData } from '../../session-manager/interfaces/ISessionUserData';
import { IABFeatureTest } from './interfaces';
import { Utils } from '../../utils';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';

export class FeatureAvailability extends FeatureAvailabilityBase {
    private _abTestActiveFeaturesDO: DeferredObject<Array<IABFeatureTest>>;
    private readonly _identityChannel: IdentityChannel;
    private readonly _userContext: IUserContext;
    private readonly _utils: Utils;
    private readonly _userDataStoreDeferredObject: DeferredObject<ISessionUserData>;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;

    constructor() {
        super();

        this._userContext = Container.get(UserContextToken);
        this._utils = Container.get(Utils);
        this._identityChannel = MessageBroker.getInstance().identity;
        this._userDataStoreDeferredObject = Container.get(UserDataStoreDeferredObjectToken);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);

        this._userDataStoreDeferredObject.promise.then(() => {
            this.ResetActiveFeatures();
            this._tryLoadAbTestActiveFeatures().then(this._subscribeToLoginMessage);

            this._subscribeToLogoutMessage();
        });
    }

    public override GetActiveAbTestFeatures = async (featureTestId?: number): Promise<any> => {
        let activeFeatures = undefined;

        this._abTestActiveFeaturesDO = await this._tryLoadAbTestActiveFeatures();

        activeFeatures = await this._abTestActiveFeaturesDO.promise;

        if (!activeFeatures || !activeFeatures.length) return null;

        if (featureTestId) {
            activeFeatures = activeFeatures.filter(
                (feature: any) => feature.TestID == featureTestId,
            );
        }

        return activeFeatures;
    };

    public ResetActiveFeatures = () => {
        if (this._abTestActiveFeaturesDO?.resolved) {
            delete this._abTestActiveFeaturesDO;
            this._abTestActiveFeaturesDO = null;
        }
    };

    private _subscribeToLoginMessage = () => {
        this._identityChannel.topics.loginSuccess.subscribe(() => {
            this.ResetActiveFeatures();
            this._tryLoadAbTestActiveFeatures();
        });
    };

    private _subscribeToLogoutMessage = () => {
        this._identityChannel.topics.logout.subscribe(() => {
            this.ResetActiveFeatures();
            this._tryLoadAbTestActiveFeatures();
        });
    };

    private _tryLoadAbTestActiveFeatures = async (): Promise<
        DeferredObject<Array<IABFeatureTest>>
    > => {
        if (
            (this._abTestActiveFeaturesDO &&
                this._abTestActiveFeaturesDO.resolved &&
                !this._abTestActiveFeaturesDO.resolvedValue) ||
            !this._abTestActiveFeaturesDO
        ) {
            this._abTestActiveFeaturesDO = new DeferredObject({
                storeResolved: true,
            });

            const activeFeatures = await this._loadAbTestActiveFeatures();
            this._abTestActiveFeaturesDO.resolve(activeFeatures);
        }

        return this._abTestActiveFeaturesDO;
    };

    private _loadAbTestActiveFeatures = async (): Promise<Array<IABFeatureTest>> => {
        await this._userDataStoreDeferredObject.promise;
        const anonymousPlayerID = this._utils.getAnonymousPlayerID();
        const { response, errorResponse } = await UserApi.GetABTestActiveFeatures(
            this._userContext.IsAuthenticated,
            { AnonymousPlayerID: anonymousPlayerID },
        );

        const isTerminatedSession =
            (errorResponse?.status == StatusCodes.BAD_REQUEST &&
                errorResponse?.data?.error?.code === ApiErrorCodes.MissingUserData) ||
            errorResponse?.status == StatusCodes.UNAUTHORIZED ||
            errorResponse?.status == StatusCodes.FORBIDDEN;

        if (isTerminatedSession) {
            const { response } = await UserApi.GetABTestActiveFeatures(false, {
                AnonymousPlayerID: anonymousPlayerID,
            });
            this._setAnonymousPlayerID(response.AnonymousPlayerID);
            return response.aBFeatureTests || null;
        }

        if (errorResponse) {
            this._logger.warn('Error while fetching AB tests for anonymous players', errorResponse);
            return null;
        }

        this._setAnonymousPlayerID(response.AnonymousPlayerID);
        return response.aBFeatureTests || null;
    };

    private _setAnonymousPlayerID = (anonymousPlayerID: string) => {
        if (anonymousPlayerID != null)
            this._localSimpleStoreService.set('anonymousPlayerID', anonymousPlayerID);
    };
}
