export default {
    Logout: 'Logout',
    LowFunds: 'LowFunds',
    TimeoutAndKick: 'TimeoutAndKick',
    UploadDocuments: 'UploadDocuments',
    Restriction: 'Restriction',
    Migration: 'Migration',
    ConfirmLocation: 'ConfirmLocation',
    StateSwitcher: 'StateSwitcher',
    LocationConfirmation: 'LocationConfirmation',
    Hide: 'Hide',
    GeoComplyRequired: 'GeoComplyRequired',
    GeolocationProcess: 'GeolocationProcess',
    GeolocationTroubleShooting: 'GeolocationTroubleShooting',
    GeolocationConfirmed: 'GeolocationConfirmed',
    GeolocationNotInUSA: 'GeolocationNotInUSA',
    GeolocationMobilePermissions: 'GeolocationMobilePermissions',
    GeolocationRetryInProgress: 'GeolocationRetryInProgress',
    GeolocationVerify: 'GeolocationVerify',
    TimeSpentReminder: 'TimeSpentReminder',
    ActivityHistory: 'ActivityHistory',
    PersonalLimits: 'PersonalLimits',
    RedirectMft: 'RedirectMft',
    NightlyLimitNotice: 'NightlyLimitNotice',
    NightlyLimitRestricted: 'NightlyLimitRestricted',
    PokerDownload: 'PokerDownload',
    PokerDownloadPcGuide: 'PokerDownloadPcGuide',
    EnvAccessAuthentication: 'EnvAccessAuthentication',
    ShowPlayerWinLoss: 'ShowPlayerWinLoss',
    ShowOnboarding: 'ShowOnboarding',
    AppUpgradeRequired: 'AppUpgradeRequired',
};
